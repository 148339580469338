"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "dashboard"
  }, [_c("el-container", [_c("div", {
    staticClass: "top_box"
  }, [_c("div", {
    staticClass: "name"
  }, [_vm._v("\n        Hi " + _vm._s(_vm.name) + "，"), _c("span", {
    staticStyle: {
      "font-size": "24px",
      "margin-left": "10px"
    }
  }, [_vm._v("欢迎回来 "), _c("i", {
    staticClass: "el-icon-message-solid",
    staticStyle: {
      color: "#e7b139",
      "font-size": "28px",
      "margin-right": "10px"
    }
  })])]), _vm._v(" "), _c("div", [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    model: {
      value: _vm.value1,
      callback: function callback($$v) {
        _vm.value1 = $$v;
      },
      expression: "value1"
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.getIndexData
    }
  }, [_vm._v("查看")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "default"
    },
    on: {
      click: _vm.resetData
    }
  }, [_vm._v("重置")])], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAdmin,
      expression: "isAdmin"
    }],
    staticClass: "card"
  }, [_c("h2", {
    staticClass: "cardTitle",
    staticStyle: {
      "margin-left": "15px",
      "margin-bottom": "20px"
    }
  }, [_vm._v("收益概览")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.contract_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("合同费收入")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "30px",
      "margin-bottom": "10px"
    }
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.rate_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单服务费收入")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkh_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣手续费收入")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣流水")])])])])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.pay_all_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("支付订单金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.rate_money_all / 100, 2)) + "元\n              ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("订单服务费抽成（0.01）")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum((_vm.data.hkh_money + _vm.data.pay_sum) / 1000 * 7, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣抽成（0.007）")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(_vm.data.ensign_sum) + "笔")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("签约订单数")])])])])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkhd_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("按时划扣回款金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(_vm.data.hkhd_num) + "期")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("按时划扣回款期数")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.hkhy_money, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("逾期划扣回款金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(_vm.data.hkhy_num) + "期")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("逾期划扣回款期数")])])])])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_hkhk_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("划扣回款总金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_yetx_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("提现总金额")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #64bd81"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-coin item_icon",
    staticStyle: {
      "background-color": "#bbddc1"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_htshc_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("商户承担合同费用")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #e0c570"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-takeaway-box item_icon",
    staticStyle: {
      "background-color": "#f7d99d"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_yczf_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("预存支付宝总金额")])])])])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #378fb4"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-s-data item_icon",
    staticStyle: {
      "background-color": "#85ccf3"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.money_qita_tj, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("其他充值")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "subitme",
    staticStyle: {
      "border-left": "5px solid #3ab8d6"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("i", {
    staticClass: "el-icon-notebook-2 item_icon",
    staticStyle: {
      "background-color": "#86e0f6"
    }
  })]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_c("div", {
    staticClass: "subitme_num"
  }, [_vm._v(_vm._s(this.formatNum(_vm.data.merchant_balance, 2)) + "元")]), _vm._v(" "), _c("div", {
    staticStyle: {
      color: "gray"
    }
  }, [_vm._v("E签宝账户余额")])])])])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "card"
  }, [_c("h2", {
    staticClass: "cardTitle",
    staticStyle: {
      "margin-left": "15px",
      "margin-bottom": "20px"
    }
  }, [_vm._v("店铺概览")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "subitme_title",
    staticStyle: {
      color: "#178ef3"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("img", {
    staticClass: "item_icon",
    attrs: {
      src: require("../../assets/shop_icon5.png"),
      alt: ""
    }
  })]), _vm._v(" "), _c("div", [_vm._v("\n                可提现金额 "), _c("sapn", {
    staticStyle: {
      "font-size": "14px",
      color: "gray"
    }
  }, [_vm._v("(低于50元不可提现)")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "subitme_num"
  }, [_vm._v("\n              " + _vm._s(this.formatNum(_vm.data.alipay_money, 2)) + "元\n            ")])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "subitme_title",
    staticStyle: {
      color: "#868af3"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("img", {
    staticClass: "item_icon",
    attrs: {
      src: require("../../assets/shop_icon1.png"),
      alt: ""
    }
  })]), _vm._v("\n              已支付订单\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "subitme_num"
  }, [_vm._v("\n              " + _vm._s(_vm.data.pay_num) + "笔\n            ")])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "subitme_title",
    staticStyle: {
      color: "#f65f14"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("img", {
    staticClass: "item_icon",
    attrs: {
      src: require("../../assets/shop_icon2.png"),
      alt: ""
    }
  })]), _vm._v("\n              订单总值\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "subitme_num"
  }, [_vm._v("\n              " + _vm._s(this.formatNum(_vm.data.all_money, 2)) + "元\n            ")])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "subitme_title",
    staticStyle: {
      color: "#20a65b"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("img", {
    staticClass: "item_icon",
    attrs: {
      src: require("../../assets/shop_icon3.png"),
      alt: ""
    }
  })]), _vm._v("\n              收到划扣流水\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "subitme_num"
  }, [_vm._v("\n              " + _vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "元\n            ")])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("div", {
    staticClass: "subitme1"
  }, [_c("div", {
    staticClass: "subitme_title",
    staticStyle: {
      color: "#fa830d"
    }
  }, [_c("div", {
    staticClass: "desktop-view"
  }, [_c("img", {
    staticClass: "item_icon",
    attrs: {
      src: require("../../assets/shop_icon4.png"),
      alt: ""
    }
  })]), _vm._v("\n              未入账金额\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "subitme_num"
  }, [_vm._v("\n              " + _vm._s(this.formatNum(_vm.data.money_wrz_tj, 2)) + "元\n            ")])])])], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-top": "15px"
    }
  }, [_c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("\n              收入数据\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "content"
  }, [_c("div", {
    staticClass: "info1"
  }, [_c("div", {
    staticClass: "txt"
  }, [_vm._v("\n                  订单总值(元)\n                ")]), _vm._v("\n                " + _vm._s(this.formatNum(_vm.data.all_money, 2)) + "\n              ")]), _vm._v(" "), _c("div", {
    staticClass: "info1 lightimg"
  }, [_c("div", {
    staticClass: "txt"
  }, [_vm._v("\n                  已收到划扣流水(元)\n                ")]), _vm._v("\n                " + _vm._s(this.formatNum(_vm.data.pay_sum, 2)) + "\n              ")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("\n              订单数据\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "content",
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("div", {
    staticClass: "left",
    staticStyle: {
      width: "45%"
    }
  }, [_c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    正常订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.zc_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    待归还\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.dgh_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    已支付新订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.pay_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    待支付新订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.to_pay_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    待签约新订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.to_deduct_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    完成扣款订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.dk_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    已完成订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.h_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    已完成\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.ywc_num) + "个")])])]), _vm._v(" "), _c("div", {
    staticClass: "right",
    staticStyle: {
      width: "45%"
    }
  }, [_c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot1"
  }), _vm._v("\n                    续费订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.xf_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot1"
  }), _vm._v("\n                    已支付续费订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.xf_pay_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot1"
  }), _vm._v("\n                    代扣续费订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.xf_dk_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot1"
  }), _vm._v("\n                    逾期订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.yq_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info3"
  }, [_c("div", {
    staticClass: "title3"
  }, [_c("div", {
    staticClass: "dot1"
  }), _vm._v("\n                    违约订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(" " + _vm._s(_vm.data.vy_num) + "个")])])])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("div", {
    staticClass: "carditem"
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("\n              投诉数据\n            ")]), _vm._v(" "), _c("div", {
    staticClass: "content",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "space-around",
      height: "200px"
    }
  }, [_c("LiquidChart", {
    ref: "LiquidChart1",
    attrs: {
      option: _vm.tousudata
    }
  }), _vm._v(" "), _c("div", [_c("div", {
    staticClass: "info2"
  }, [_c("div", {
    staticClass: "title2"
  }, [_c("div", {
    staticClass: "dot"
  }), _vm._v("\n                    投诉订单\n                  ")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.data.ts_num) + "个")])]), _vm._v(" "), _c("div", {
    staticClass: "info2",
    staticStyle: {
      "margin-top": "30px"
    }
  }, [_c("div", {
    staticClass: "title2"
  }, [_c("div", {
    staticClass: "dot1"
  }), _vm._v("\n                    代扣投诉\n                  ")]), _vm._v(" "), _c("div", [_vm._v(_vm._s(_vm.data.dk_ts_num) + "个")])])])], 1)])])], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;