"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-page-header", {
    attrs: {
      title: "返回"
    },
    on: {
      back: _vm.goBack
    }
  }), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "修改产品",
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("GoodForm", {
    attrs: {
      isEdit: true,
      pk: _vm.pk,
      is_day_nums: _vm.is_day_nums
    },
    on: {
      "edit-good": _vm.editGood,
      cancel: _vm.cancel
    }
  })], 1), _vm._v(" "), _c("el-row", [_c("el-card", {
    staticStyle: {
      padding: "10px 0",
      "margin-top": "10px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "20px",
      "font-weight": "600",
      "font-size": "20px",
      "margin-right": "20px"
    }
  }, [_vm._v("产品信息")]), _vm._v(" "), _c("el-button", {
    staticClass: "filter-item",
    staticStyle: {
      float: "right",
      "margin-right": "10px"
    },
    attrs: {
      type: "warning",
      icon: "el-icon-edit",
      size: "small"
    },
    on: {
      click: _vm.handleEdit
    }
  }, [_vm._v("修改产品信息")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right",
      "margin-right": "10px"
    },
    attrs: {
      icon: "el-icon-refresh-right",
      size: "small",
      type: "success"
    },
    on: {
      click: _vm.getList
    }
  }, [_vm._v("刷新页面")])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "list"
  }, [_c("el-image", {
    staticStyle: {
      "max-width": "350px"
    },
    attrs: {
      src: _vm.good_img,
      alt: "产品头图"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("产品名称："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.name))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("创建时间："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.create_time))])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.order_type === 2,
      expression: "document.order_type === 2"
    }],
    staticClass: "item"
  }, [_vm._v("产品分类："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.main_class))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("扣费方式："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.cost_type === 1 ? "天" : _vm.document.cost_type === 2 ? "月" : "周"))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("下单方式："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.order_mode === 1 ? "先用后付" : "先付后用"))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("上架状态："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.active))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("编号："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.sequence))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("成本金额："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.cost_money) + "元")])])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "list"
  }, [_c("div", {
    staticClass: "item"
  }, [_vm._v("下单类型："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.order_type === 1 ? "缴费" : "租赁"))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("扣款总额："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.money) + "元")])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.order_type === 2,
      expression: "document.order_type === 2"
    }],
    staticClass: "item"
  }, [_vm._v("扣款期数："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.cost_num) + "期")])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.order_type === 2,
      expression: "document.order_type === 2"
    }],
    staticClass: "item"
  }, [_vm._v("扣款规则："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.cost_rule))])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.order_type === 2,
      expression: "document.order_type === 2"
    }],
    staticClass: "item"
  }, [_vm._v("提前扣款天数："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.q_cost_day ? _vm.document.q_cost_day : "暂无") + "(天)")])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.order_type === 2,
      expression: "document.order_type === 2"
    }],
    staticClass: "item"
  }, [_vm._v("买断开关："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.d_cost_type === 1 ? "开启" : "关闭"))])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.document.d_cost_money,
      expression: "document.d_cost_money"
    }],
    staticClass: "item"
  }, [_vm._v("买断价格："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.d_cost_money))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("备注提示信息："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.remark))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("商品参数名称："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.parameter == "无" ? "" : _vm.document.parameter))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("商品备选参数："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(this.document.parameter_value))])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("押金（原首付款）："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.deposit) + "元")])]), _vm._v(" "), _c("div", {
    staticClass: "item"
  }, [_vm._v("押金（弃用）："), _c("span", {
    staticClass: "itemInfo"
  }, [_vm._v(_vm._s(_vm.document.deposit) + "元")])]), _vm._v(" "), _c("div", {
    staticClass: "item",
    staticStyle: {
      "border-bottom": "0"
    }
  }, [_vm._v("产品支付二维码                          \n            ")])])])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;